/*----------------------------------------*/
/* Template default CSS
/*----------------------------------------*/

html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #2E2E2E94;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  font-size: 16px;
  font-family: "PT Sans", sans-serif;
  color: #2E2E2E;
  font-weight: 400;
  line-height: 28px;
  margin: 0 0 15px 0;
}


.hamburger-box {
  width: 25px;
  height: 20px;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  width: 30px;
  height: 3px;
}

.cart-total {
  width: 100%;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px;
}

.cart-total h3 {
  font-size: 16px;
  margin-bottom: 20px;
}

.cart-total p {
  width: 100%;
  display: block;
}

.cart-total p span {
  display: block;
  width: 50%;
}

.cart-total p.total-price span {
  text-transform: uppercase;
}

.cart-total p.total-price span:last-child {
  color: #000000;
  font-weight: 600;
}

.cart-total hr {
  background: rgba(255, 255, 255, 0.1);
}

.cart-wrap .btn-primary {
  display: inline-block;
}

/*Font Variables*/
@font-face {
  font-family: 'Action of the Time New';
  src: url('assets//fonts/Action of the Time New/Action of the Time New.ttf') format('truetype');
}


.site-section .display-4 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.2;
  color: #fbb03b;
}

@media (min-width: 768px) {
  .site-section {
    padding: 8em 0;
  }
}

.site-section.site-section-sm {
  padding: 4em 0;
}

.bg-light {
  background: #ccc;
}

.site-logo-font {
  font-family: Action of the Time New;
  font-size: 24px;
}

.hero-wrap {
  width: 100%;
  position: relative;
}

.overlay {
  background: rgba(0, 0, 0, 0.3);
  opacity: 0.9;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-wrap .slider-text {
  color: #fff;
  position: relative;
}

.hero-wrap .slider-text .breadcrumbs {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 0;
  z-index: 99;
  font-weight: 300;
}

.hero-wrap .slider-text .breadcrumbs span {
  color: white;
}

.hero-wrap .slider-text .breadcrumbs span a {
  color: #fff;
}

.hero-wrap .slider-text .bread {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
  color: white;
}

.hero-wrap .slider-text .btn-primary {
  border: 1px solid rgba(255, 255, 255, 0.4);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

.hero-wrap .slider-text .btn-primary:hover,
.hero-wrap .slider-text .btn-primary:focus {
  background: #fff !important;
  color: #000000;
}

.hero-wrap.hero-bread {
  padding: 8em 0;
}

.hero-wrap,
.img,
.blog-img,
.user-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 50vh;
}

.delete a {
  padding: 4px 10px;
  color: #000000;
}

.delete a:hover {
  cursor: pointer;
}

.row-hover:hover {
  color: #55595c;
  background-color: rgba(0, 0, 0, 0.075);
  cursor: pointer;
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Required for Google map visibility */
agm-map {
  height: 300px;
}

/* App Pre-loader */
.loading-screen-wrapper {
  z-index: 100000;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  display: block;
}

/*---------------------
  Card, Charts, Accordions
-----------------------*/

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.35rem - 1px);
  border-top-right-radius: calc(0.35rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.35rem - 1px);
  border-bottom-left-radius: calc(0.35rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
}

.card-header:first-child {
  border-radius: calc(0.35rem - 1px) calc(0.35rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f8f9fc;
  border-top: 1px solid #e3e6f0;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.35rem - 1px) calc(0.35rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.35rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.35rem - 1px);
  border-top-right-radius: calc(0.35rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.35rem - 1px);
  border-bottom-left-radius: calc(0.35rem - 1px);
}

.card-deck .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 0.75rem;
    margin-bottom: 0;
    margin-left: 0.75rem;
  }
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.card .card-header .dropdown {
  line-height: 1;
}

.card .card-header .dropdown .dropdown-menu {
  line-height: 1.5;
}

.card .card-header[data-toggle="collapse"] {
  text-decoration: none;
  position: relative;
  padding: 0.75rem 3.25rem 0.75rem 1.25rem;
}

.card .card-header[data-toggle="collapse"]::after {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 1.725rem;
  line-height: 51px;
  font-weight: 900;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  color: #d1d3e2;
}

.card .card-header[data-toggle="collapse"].collapsed {
  border-radius: 0.35rem;
}

.card .card-header[data-toggle="collapse"].collapsed::after {
  content: '\f105';
}

.chart-area {
  position: relative;
  height: 10rem;
  width: 100%;
}

@media (min-width: 768px) {
  .chart-area {
    height: 20rem;
  }
}

.container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.chart-bar {
  position: relative;
  height: 10rem;
  width: 100%;
}

.border-left-primary {
  border-left: 0.25rem solid #4e73df !important;
}

.border-left-secondary {
  border-left: 0.25rem solid #858796 !important;
}

.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}

.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}

.border-left-warning {
  border-left: 0.25rem solid #f6c23e !important;
}

.border-left-danger {
  border-left: 0.25rem solid #e74a3b !important;
}

.border-left-light {
  border-left: 0.25rem solid #f8f9fc !important;
}

.border-left-dark {
  border-left: 0.25rem solid #5a5c69 !important;
}


@media (min-width: 768px) {
  .chart-bar {
    height: 20rem;
  }
}

.chart-pie {
  position: relative;
  height: 20rem;
  width: 100%;
}

@media (min-width: 768px) {
  .chart-pie {
    height: calc(20rem) !important;
  }
}

/*---------------------
  Profile
-----------------------*/
.profile__form form input {
  width: 100%;
  height: 50px;
  border: 1px solid #e1e1e1;
  background: #fafafa;
  font-size: 14px;
  color: #111111;
  padding-left: 20px;
  margin-bottom: 30px;
}
.profile__form form input:last-child {
  color: #111111;
}
.profile__form form textarea {
  width: 100%;
  height: 140px;
  border: 1px solid #e1e1e1;
  background: #fafafa;
  font-size: 14px;
  color: #111111;
  padding-left: 20px;
  padding-top: 12px;
  margin-bottom: 24px;
  resize: none;
}
.profile__form form textarea:last-child {
  color: #111111;
}

.card-profile {
  margin-top: 30px;
  text-align: center;
}
.card-profile .card-avatar {
  margin: -50px auto 0;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  max-width: 130px;
  max-height: 130px;
}
.card-profile .card-avatar + .card-body {
  margin-top: 15px;
}
.card-profile .card-avatar img {
  width: 100%;
  height: auto;
}
.card-profile .card-body + .card-footer {
  margin-top: -15px;
}
.card-profile .card-footer .btn.btn-just-icon {
  font-size: 20px;
  padding: 12px 12px;
  line-height: 1em;
}
.card-profile.card-plain .card-avatar {
  margin-top: 0;
}
.card-profile .card-header:not([class*="card-header-"]) {
  background: transparent;
}

/*---------------------
  Order Details
-----------------------*/

.checkout__order {
  background: #fdf3ea;
  padding: 30px;
}
.checkout__order .order__title {
  color: #111111;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 25px;
  margin-bottom: 30px;
}
.checkout__order p {
  color: #444444;
  font-size: 16px;
}
.checkout__order .site-btn {
  width: 100%;
  margin-top: 8px;
  letter-spacing: 0;
}

.checkout__order__products {
  font-size: 16px;
  color: #111111;
  overflow: hidden;
  margin-bottom: 18px;
  font-weight: 600;
}
.checkout__order__products span {
  float: right;
}

.checkout__total__products {
  margin-bottom: 20px;
}
.checkout__total__products li {
  font-size: 16px;
  color: #444444;
  list-style: none;
  line-height: 26px;
  overflow: hidden;
  margin-bottom: 15px;
  font-weight: 500;
}
.checkout__total__products li:last-child {
  margin-bottom: 0;
}
.checkout__total__products li samp {
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.checkout__total__products li span {
  color: #111111;
  float: right;
  font-weight: 600;
}

.checkout__total__all {
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  padding: 15px 0;
  margin-bottom: 26px;
}

.checkout__total__all li {
  list-style: none;
  font-size: 16px;
  color: #111111;
  line-height: 40px;
  font-weight: 600;
  overflow: hidden;
}
.checkout__total__all li span {
  color: #fbb03b;
  float: right;
}

/* Small Device = 320px */
@media only screen and (max-width: 479px) { 
  
  .checkout__order {
      margin-top: 20px;
    }
  
    .checkout__order__products span {
      display: none;
    }

}
